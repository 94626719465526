<script>
import { defineComponent } from 'vue'
import { formatDate } from '/~/utils/format/date'
import { formatPhone } from '/~/utils/format/string'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseImage from '/~/components/base/image/base-image.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import DrawerAddress from '/~/components/drawer/views/personal-details/components/drawer-address.vue'
import { useAddresses } from '/~/composables/addresses'
import { useCms } from '/~/composables/cms/use-cms'
import { useProvider } from '/~/composables/provider'
import { useUser } from '/~/composables/user'

export default defineComponent({
  name: 'profile-personal-details',
  components: {
    BaseLoader,
    BaseImage,
    BaseAsidePage,
    BaseIcon,
    DrawerAddress,
  },
  props: {
    to: {
      type: String,
      default: 'menu-modal',
    },
    close: {
      type: Function,
      default: null,
    },
  },
  setup() {
    const { user } = useUser()
    const { addresses, getAddresses } = useAddresses()
    const { isDarkThemeForEwallet } = useCms()
    const { profileConfigForm, isUserAddressEnabled, isProfileFormEditable } =
      useProvider()

    return {
      user,
      formatDate,
      addresses,
      getAddresses,
      isDarkThemeForEwallet,
      profileConfigForm,
      isUserAddressEnabled,
      isProfileFormEditable,
    }
  },
  data() {
    return {
      editIndex: null,
    }
  },
  computed: {
    sortedAddresses() {
      const items = [...this.addresses]

      return items.sort((item) => (item.default ? -1 : 1))
    },
    isAddressesLoading() {
      return this.addresses === null
    },
    isAddressesEmpty() {
      return (this.addresses?.length ?? 0) === 0
    },
    info() {
      const {
        firstName,
        lastName,
        startDate,
        email,
        mobile,
        username,
        dob: birthDate,
        sex,
      } = this.profileConfigForm

      return [
        this.getItem(firstName, 'First name', this.user.firstName),
        this.getItem(lastName, 'Last name', this.user.lastName),
        this.getItem(
          startDate,
          'Start date',
          formatDate('daymonthyearnumeric', this.user.startDate)
        ),
        this.getItem(email, 'Email', this.user.email),
        this.getItem(mobile, 'Mobile', formatPhone(this.user.mobile)),
        this.getItem(username, 'User name', this.user.username),
        this.getItem(
          birthDate,
          'Date of birth',
          formatDate('daymonthyearnumeric', this.user.birthDate)
        ),
        this.getItem(sex, 'Gender', this.user.sexText),
      ]
    },
    bio() {
      const { bio } = this.profileConfigForm

      return this.getItem(bio, 'Bio', this.user.bio)
    },
  },
  created() {
    this.getAddresses()
  },
  methods: {
    getItem(config, defaultTitle, value) {
      return {
        title: config?.label || defaultTitle,
        value,
        isVisible: config?.isVisible ?? false,
      }
    },
    onEditProfile() {
      this.$router.push({ hash: '#profile-edit-profile' })
    },
    onAddAddress() {
      this.$router.push({ hash: '#profile-add-address' })
    },
  },
})
</script>

<template>
  <base-aside-page ref="baseAsidePage" title="Personal Details" no-padding>
    <div class="px-5 pb-5">
      <div v-if="profileConfigForm.isVisible" class="mb-[30px] space-y-4">
        <div class="flex items-center justify-between">
          <span
            class="font-bold"
            :class="{
              'text-eonx-neutral-600': !isDarkThemeForEwallet,
              'text-white': isDarkThemeForEwallet,
            }"
          >
            Profile
          </span>
          <button
            v-if="isProfileFormEditable"
            class="flex flex-row items-center text-primary"
            aria-label="Edit profile"
            @click="onEditProfile"
          >
            <span class="mr-2.5 font-bold">Edit</span>
            <base-icon svg="points-only/pencil" :size="16" />
          </button>
        </div>
        <template v-for="(item, index) of info">
          <div
            v-if="item.isVisible"
            :key="index"
            class="rounded-xl bg-gray-50 px-6 py-5"
          >
            <div class="text-eonx-neutral-600">
              {{ item.title }}
            </div>
            <div
              class="overflow-hidden text-ellipsis font-bold text-eonx-neutral-800"
            >
              {{ item.value || '-' }}
            </div>
          </div>
        </template>
      </div>
      <template v-if="isUserAddressEnabled">
        <div class="mt-5 flex items-center justify-between">
          <span
            class="font-bold"
            :class="{
              'text-eonx-neutral-600': !isDarkThemeForEwallet,
              'text-white': isDarkThemeForEwallet,
            }"
          >
            Addresses
          </span>
          <button
            class="flex flex-row items-center text-primary"
            aria-label="Add address"
            @click="onAddAddress"
          >
            <span class="mr-2.5 font-bold">Add</span>
            <base-icon svg="v2/custom/plus" :size="14" />
          </button>
        </div>
        <base-loader v-if="isAddressesLoading" fullwidth class="mt-5" />
        <div v-else-if="isAddressesEmpty" class="mt-5 flex items-center">
          <base-image src="address-illustration.svg" class="mx-5 w-1/3" />

          <div>
            <div class="mb-[5px] text-xl font-bold">No address stored</div>
            <div>Add your address for a speedy checkout process.</div>
          </div>
        </div>
        <template v-else>
          <div
            v-for="address of sortedAddresses"
            :key="address.id"
            class="mt-5"
          >
            <drawer-address :address="address" />
          </div>
        </template>
      </template>
    </div>
  </base-aside-page>
</template>
